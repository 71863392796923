<div class="bgded overlay" style="background-image:url('../../assets/images/rochester1 cropped.jpeg');">
  <div id="pageintro" class="hoc clear">
    <div class="flexslider basicslider">
      <ul class="headerSlides">
        <li>
          <article>
            <h3 class="heading">Hi, I'm Tom.</h3>
<!--            <p>Web developer and user experience designer</p>-->
            <footer>
              <div class="contact-btn" style="padding-left: 2em; padding-right: 2em">
                <a class="btn" href="../../assets/resume/Tom Pickren Resume.pdf" target="_blank" alt="Resume" title="Resume">Resume</a>
              </div>
            </footer>
          </article>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="wrapper row3">
  <main class="hoc container clear">
    <div class="btmspace-80 center">
      <div class="left one_half first" id="about-me-img">
        <img src="../../assets/images/rochester1-3 crop.jpeg" alt="background_img" id="portrait">
      </div>
      <div class="one_half">
        <h3 class="nospace" id="bodyHeader">About Me</h3>
        <br>
        <p class="nospace">I'm a web developer and UX / UI designer. I bring logic and beauty to challenging design
          problems by keeping simplicity, accessibility, and functionality in mind.</p>
        <br>
        <br>
        <br>
        <p class="nospace">In my free time, I like to produce music, rock climb, and watch b-movies.</p>
      </div>
    </div>
    <div class="clear"></div>
  </main>
</div>
