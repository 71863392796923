<div class="bgded overlay">
  <div class="wrapper row1">
    <header id="header" class="hoc clear">

      <!--      TODO fix alignment-->


      <nav>
        <div id="mainav">
          <div class="fl_left">
            <h1>
              <a [routerLink]="['/']" style="color: #FFFFFF">TP</a>
            </h1>
          </div>

          <div class="fl_right">
            <div class="fl_left">
              <h3>
                <a [routerLink]="['/']" style="color: #FFFFFF" >Home</a>
              </h3>
            </div>
            <div class="fl_right" id="contactNav">
              <h3>
                <a [routerLink]="['/contact']" style="color: #FFFFFF">Contact</a>
              </h3>
            </div>
          </div>



        </div>






      </nav>
    </header>
  </div>
</div>
